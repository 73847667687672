import qs from "qs";
import axios from "@/interceptor/HttpInterceptor";
import HttpConstant from "@/const/HttpConstant";
import TokenUtil from "@/utils/TokenUtil";
import ObjectUtil from "./ObjectUtil";
export default {
  /**
   *
   * 自定义get请求
   *
   */
  get(url: string, params: any, config?: any) {
    if (!params) {
      params = {};
    } else {
      Object.keys(params).forEach((key, index) => {
        if (!url.includes("?") && index === 0) {
          url += "?" + key + "=" + params[key];
        } else {
          url += "&" + key + "=" + params[key];
        }
      });
    }
    if (!config) {
      config = {};
    }
    return axios.get(url, config);
  },

  /**
   *
   * 自定义post请求
   *
   */
  post(url: string, params: any, config?: any) {
    if (!params) {
      params = {};
    }
    if (!config) {
      config = {};
    } else if (config["Content-Type"] == HttpConstant.FORM_DATA_REQ) {
      params = qs.stringify(params);
    }
    return axios.post(url, params, config);
  },

  /**
   *
   * 自定义文件上传方法
   *
   */

  upload(url: string, params: any, config?: any) {
    if (!config) {
      config = axios.defaults.headers;
    }
    let configTmp = Object.assign({}, config);
    configTmp["timeout"] = 1000 * 60 * 5;
    configTmp["Content-Type"] = HttpConstant.MUL_FORM_REQ;
    configTmp["Authorization"] = TokenUtil.getToken();
    let formData = new FormData(); //创建form对象

    for (let field in params) {
      if (params[field]) {
        formData.append(field, params[field]);
      }
    }
    return axios.post(url, formData, configTmp);
  },

  /**
   *
   * 自定义文件下载方法
   *
   */
  download(url: string, params: any, config?: any) {
    var paramUrl = "?";
    for (let field in params) {
      if (params[field]) {
        paramUrl = paramUrl + field + "=" + params[field] + "&";
      }
    }
    let token = TokenUtil.getToken();
    paramUrl += `token=${token}`;
    console.log(url + paramUrl);
    window.location.href = url + paramUrl;
  },

  /**
   *
   * 自定义文件预览方法
   *
   */
  preview(url: string, params: any, config?: any) {
    let paramUrl = "?";
    for (let field in params) {
      if (params[field]) {
        paramUrl = paramUrl + field + "=" + params[field] + "&";
      }
    }
    let token = TokenUtil.getToken();
    paramUrl += `token=${token}`;
    window.open(url + paramUrl);
  },
};
