export interface FormPropertiesModel {
  labelWidth: number;
  showLabel: boolean;
  size: string;
  labelPosition: string;
  disabled: boolean;
  hasBorder: boolean;
  borderColor?: string;
  leftPadding: number;
  rightPadding: number;
}

export const FormPropertiesData = {
  labelWidth: 120,
  showLabel: true,
  size: "medium",
  labelPosition: "right",
  disabled: false,
  hasBorder: false,
  borderColor: "#333333",
  leftPadding: 0,
  rightPadding: 0,
};
