import HttpUtil from '@/utils/HttpUtil';
import CryptoUtil from '@/utils/CryptoUtil';
const PUBLIC_KEY: string =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgKEtllxrAZ1izOvtQbsv89sTp1KwNiqe3zoVEWu49CV80CntN2P42xi+kwx+DvrJx75WW0LpXOKkuAUUoBWrBgMh+Kx5+XwasPX36WfWtQ3OpJzmUPTvDD60w/gVpVc1xCYzRGNqSP/OBZyT/SxJDitD4UpTranpklRsIlv/+vwIDAQAB';
export default {
  login(params?: any) {
    var url = import.meta.env.VITE_APP_SERVER_CODE + 'login/code';
    return HttpUtil.post(url, params);
  },

  //获取登录后的缓存信息
  getLoginCache(params?: any) {
    let url = import.meta.env.VITE_APP_SERVER_CODE + 'login/identity';
    return HttpUtil.get(url, params);
  },

  //临时测试登录接口
  temporaryLogin(params: any) {
    let rsaPwd = CryptoUtil.setRsa(params.password);
    params.password = rsaPwd;
    var url = '/auth/accessCode';
    return HttpUtil.post(url, params);
  },

  /**
   * 切换公司
   * @param tenantId 租户id
   */
  identity(tenantId: string) {
    var url = import.meta.env.VITE_APP_SERVER_CODE + 'login/identity';
    return HttpUtil.get(url, { tenantId });
  },

  getAccessCode: (params: any) => {
    let url = `${process.env.VITE_APP_SSO_CONTEXT}id/auth/accessCode`;
    params.password = CryptoUtil.setRsa(params.password, PUBLIC_KEY);
    return HttpUtil.post(url, params);
  },
  ssoLogin: (params: any) => {
    let url = `${process.env.VITE_APP_SSO_CONTEXT}id/auth/login?clientId=${params.clientId}&accessCode=${params.accessCode}`;
    if (params.redirectUrl) {
      url += `&redirectUrl=${params.redirectUrl}`;
    }
    window.location.href = url;
  },
  ssoDeriction: (params: any) => {
    let url = `${process.env.VITE_APP_SSO_CONTEXT}id/auth/detection?clientId=${params.clientId}`;
    if (params.ssoCallBack) {
      url += `&ssoCallBack=${params.ssoCallBack}`;
    }
    window.location.href = url;
  },
  h5Deriction: (params: any) => {
    let url = `${process.env.VITE_APP_SSO_CONTEXT}sso/h5/detection?clientId=${params.clientId}&clientIp=${params.clientIp}&ticket=${params.ticket}`;
    if (params.ssoCallBack) {
      url += `&ssoCallBack=${params.ssoCallBack}`;
    }
    window.location.href = url;
  },
  h5DerictionByUser: (params: any) => {
    let url = `${process.env.VITE_APP_SSO_CONTEXT}sso/h5/detection/by/user?clientId=${params.clientId}&userId=${params.userId}`;
    if (params.ssoCallBack) {
      url += `&ssoCallBack=${params.ssoCallBack}`;
    }
    window.location.href = url;
  }
};
