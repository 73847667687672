import { buildVariableTree, buildWidgetTreeForDataSource, buildFormWidgetMap } from '@/components/form-design/utils/index';
import { FormData } from '@/models/form/FormModel';
import { WidgetData } from '@/models/form/WidgetModel';
import { WidgetDataSourceConfigTypeEnum } from '@/models/form/WidgetDataSourceModel';

export default {
  state: {
    formConfigs: FormData,
    formWidgetsValueMap: {},
    businessDataSource: [],
    activeWidget: WidgetData,
    functions: [],
    dataSourceStruct: []
  },
  mutations: {
    SET_FORM_CONFIG(state: any, data: any) {
      let formConfigs = {
        // ...state.formConfigs,
        [data.id]: data
      };
      state.formConfigs = formConfigs;
    },
    SET_BUSINESS_DATA_SOURCE(state: any, data: any) {
      state.businessDataSource = data;
    },
    SET_FORM_WIDGETS_VALUE_MAP(state: any, data: any) {
      state.formWidgetsValueMap = data;
    },
    SET_ACTIVED_WIDGET(state: any, data: any) {
      state.activeWidget = data;
    },
    SET_FORM_FUNCTIONS(state: any, data: any) {
      state.functions = data;
    },
    SET_FORM_DATASOURCE_STRUCT(state: any, data: any) {
      state.dataSourceStruct = data;
    }
  },
  getters: {
    /**
     * @description: 获取当前表单配置
     * @return:
     */
    formConfigs: (state: any) => {
      return state.formConfigs;
    },

    /**
     * @description: 获取当前表单项的值
     * @return:
     */
    formWidgetsValueMap: (state: any) => {
      return state.formWidgetsValueMap;
    },

    // 获取当前选中的组件
    activeWidget: (state: any) => {
      return state.activeWidget;
    },

    // 配置数据源时，获取数据源的值的所有选项
    dataSourceMaps: (state: any) => {
      let result: any = {};
      let formConfigs = state.formConfigs;
      for (const key in formConfigs) {
        let formConfig = formConfigs[key];
        if (formConfig && formConfig.container && formConfig.container.components && formConfig.container.components.length) {
          let variableTree = buildVariableTree(formConfig.container.paramsOptions);
          let widgetTree = buildWidgetTreeForDataSource(formConfig.container.components);
          let businessDataTree = {
            valueType: 'FIXED_VALUE',
            data: state.businessDataSource
          };
          result[formConfig.id] = [variableTree, widgetTree, businessDataTree];
        }
      }
      return result;
    },

    // 配置表达式时，获取可用的函数
    functions: (state: any) => {
      return state.functions;
    },

    // 获取数据源的结构
    dataSourceStruct: (state: any) => {
      let result: any = {};
      state.widgetConfigStruct.dataSourceConfigs.map((item: any) => {
        let tmp: any = {
          name: item.name,
          type: item.type
        };
        if (item.type != WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
          tmp.complexColumns = item.columnConfig.defaultComplexColumns;
          tmp.simpleColumn = item.columnConfig.defaultSimpleColumn;
          tmp.dataConditions = item.conditionConfig.conditions;
        }
        if (item.type == WidgetDataSourceConfigTypeEnum.OPINION_CONFIG) {
          tmp.processCode = item.processCode;
          tmp.lastNode = item.lastNode;
          tmp.lastOperator = item.lastOperator;
        }
        result[item.type] = tmp;
      });
      return result;
    },

    // 触发器类型
    actionStruct: (state: any) => {
      let result: any = {};
      state.widgetConfigStruct.actions.forEach((item: any) => {
        result[item.type] = item;
      });
      return result;
    },

    // 获取数据源的结构
    widgetConfigMapping: (state: any) => {
      return state.widgetConfigMapping;
    },

    // 表单项map
    formWidgetMap: (state: any) => {
      let result: any = {};
      let formConfigs = state.formConfigs;
      for (const key in formConfigs) {
        let formConfig = formConfigs[key];
        result[key] = buildFormWidgetMap(formConfig.container.components);
      }
      return result;
    }
  }
};
