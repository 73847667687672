import { WidgetModel } from '@/models/form/WidgetModel';
import { store } from '@/store';
import SystemUtil from '@/utils/SystemUtil';

export function getFormConfig(formId: any) {
  if (formId) {
    return store.getters.formConfigs[formId];
  }
  return SystemUtil.cloneDeep(FormData);
}

export function isRow(obj: Record<string, any>) {
  return obj.compType === 'row';
}

export function isTable(obj: Record<string, any>) {
  return obj.compType === 'table';
}

export function isCard(obj: Record<string, any>) {
  return obj.compType === 'card';
}

// 构建表单控件树
export function buildFormWidgetTree(widgets: any) {
  let widgetTree: any = [];
  if (widgets && widgets.length) {
    widgets.forEach((widgetItem: any, widgetItemIndex: number) => {
      let treeItem: any = {
        id: widgetItem.id,
        label: widgetItem.compName,
        compCode: widgetItem.compCode,
        compType: widgetItem.compType,
        dataType: widgetItem.dataType
      };
      buildWidgetChildren(widgetItem, treeItem);
      widgetTree.push(treeItem);
    });
  }
  return widgetTree;
}

function buildWidgetChildren(widget: any, treeItem: any) {
  if ('card' == widget.compType) {
    treeItem.children = [];
    if (widget.properties.components) {
      widget.properties.components.forEach((cardComponent: any, cardComponentIndex: number) => {
        let cardItem: any = {
          id: cardComponent.id,
          compCode: cardComponent.compCode,
          label: cardComponent.compName,
          compType: cardComponent.compType,
          dataType: cardComponent.dataType
        };
        buildWidgetChildren(cardComponent, cardItem);
        treeItem.children.push(cardItem);
      });
    }
  } else if ('row' == widget.compType) {
    treeItem.children = [];
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget: any, gridCellWidgetIndex: number) => {
          let gridItem = {
            id: gridCellWidget.id,
            compCode: gridCellWidget.compCode,
            label: gridCellWidget.compName,
            compType: gridCellWidget.compType,
            dataType: gridCellWidget.dataType
          };
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if ('spliter' == widget.compType) {
    treeItem.children = [];
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget: any) => {
          let gridItem = {
            id: gridCellWidget.id,
            label: gridCellWidget.compName,
            compCode: gridCellWidget.compCode,
            compType: gridCellWidget.compType,
            dataType: gridCellWidget.dataType
          };
          buildWidgetChildren(gridCellWidget, gridItem);
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if ('table' == widget.compType) {
    treeItem.children = [];
    if (widget.properties.components) {
      widget.properties.components.forEach((tableCellWidget: any, tableCellWidgetIndex: number) => {
        let tableItem = {
          id: tableCellWidget.id,
          compCode: tableCellWidget.compCode,
          label: tableCellWidget.compName,
          compType: tableCellWidget.compType,
          dataType: tableCellWidget.dataType
        };
        treeItem.children.push(tableItem);
      });
    }
  } else if (widget.dataSource && widget.dataSource.dataList) {
    treeItem.dataSource = widget.dataSource.dataList;
  }
}

// 构建表单项map
export function buildFormWidgetMap(formWidgets: WidgetModel[] = []) {
  let formWidgetsMap: any = {};
  formWidgets!.forEach(item => {
    formWidgetsMap[item.compCode as string] = item;
    if (item.compType == 'card') {
      formWidgetsMap = Object.assign(formWidgetsMap, buildFormWidgetMap(item.properties.components));
    } else if (item.compType == 'row') {
      item.properties.gridColumns!.forEach(el => {
        el.components!.forEach(child => {
          formWidgetsMap[child.compCode as string] = child;
        });
      });
    } else if (item.compType == 'spliter') {
      item.properties.gridColumns!.forEach(el => {
        formWidgetsMap = Object.assign(formWidgetsMap, buildFormWidgetMap(el.components));
      });
    } else if (item.compType == 'table') {
      item.properties.components!.forEach(el => {
        formWidgetsMap[el.compCode as string] = el;
      });
    }
  });
  return formWidgetsMap;
}

// 获取该表单项配置的数据源配置
export function getCurrentDataSourceConfig(formWidget: WidgetModel) {
  if (formWidget.dataSource && formWidget.dataSource.dataSourceConfig) {
    return formWidget.dataSource.dataSourceConfig;
  }
  return null;
}

export function buildVariableTree(variableData: any) {
  let variableTree: any = {
    valueType: 'FORM_ARGUMENT',
    data: []
  };
  if (variableData && variableData.length) {
    let variableTreeRoot: any = {
      code: 'FORM_ARGUMENT',
      text: '表单参数',
      disabled: true,
      valueType: 'FORM_ARGUMENT',
      children: []
    };
    variableData.forEach((item: any) => {
      let temp: any = {
        code: item.name,
        text: item.text,
        disabled: false,
        dataSourceConfigType: item.dataSourceConfigType,
        type: item.type,
        valueType: 'FORM_ARGUMENT'
      };
      variableTreeRoot.children.push(temp);
    });
    variableTree.data = [variableTreeRoot];
  }
  return variableTree;
}

export function buildWidgetTreeForDataSource(widgetList: any[]) {
  let widgetTree: any[] = [];
  if (widgetList && widgetList.length) {
    let rootNode: any = {
      id: 'FORM_DATA',
      code: 'FORM_DATA',
      text: '表单组件',
      disabled: true,
      valueType: 'FORM_DATA',
      children: []
    };
    widgetList.forEach((widgetItem: any, widgetItemIndex: number) => {
      let childNode: any = {
        id: widgetItem.id,
        code: widgetItem.compCode,
        text: widgetItem.compName,
        disabled: false,
        valueType: 'FORM_DATA'
      };
      buildWidgetChildrenForDataSource(widgetItem, childNode);
      rootNode.children.push(childNode);
    });
    widgetTree = [rootNode];
  }
  return { valueType: 'FORM_DATA', data: widgetTree };
}

function buildWidgetChildrenForDataSource(widget: any, treeItem: any) {
  if ('card' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    if (widget.properties.components) {
      widget.properties.components.forEach((cardComponent: any, cardComponentIndex: number) => {
        let cardItem = {
          id: cardComponent.id,
          code: cardComponent.compCode,
          text: cardComponent.compName,
          disabled: false,
          valueType: 'FORM_DATA'
        };
        buildWidgetChildrenForDataSource(cardComponent, cardItem);
        treeItem.children.push(cardItem);
      });
    }
  } else if ('row' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget: any, gridCellWidgetIndex: number) => {
          let gridItem = {
            id: gridCellWidget.id,
            code: gridCellWidget.compCode,
            text: gridCellWidget.compName,
            disabled: false,
            valueType: 'FORM_DATA'
          };
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if ('spliter' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget: any) => {
          let gridItem = {
            id: gridCellWidget.id,
            code: gridCellWidget.compCode,
            text: gridCellWidget.compName,
            disabled: false,
            valueType: 'FORM_DATA'
          };
          buildWidgetChildrenForDataSource(gridCellWidget, gridItem);
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if ('table' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    if (widget.properties.components) {
      widget.properties.components.forEach((tableCellWidget: any, tableCellWidgetIndex: number) => {
        let tableItem = {
          id: tableCellWidget.id,
          code: tableCellWidget.compCode,
          text: tableCellWidget.compName,
          disabled: true,
          valueType: 'FORM_DATA'
        };
        treeItem.children.push(tableItem);
      });
    }
  }
}

/**
 * 获取逻辑比较符
 * @param {number} param
 * @returns {number}
 */
export function getCompares(type: string) {
  let result: any[] = [];
  let comparesMaps: any[] = store.getters.compares || [];
  comparesMaps.forEach((item: any) => {
    if (item.dataType == type) {
      result = item.compares;
    }
  });
  return result;
}

/**
 * 移除无用的属性
 */
export function removeAuxiliaryProps(widgets: any) {
  let widgetsClone = [];
  if (widgets && widgets.length) {
    widgetsClone = widgets.map((widgetItem: any, widgetItemIndex: number) => {
      return removeAuxiliaryPropsInChildren(widgetItem);
    });
  }
  return widgetsClone;
}

function removeAuxiliaryPropsInChildren(widget: any) {
  if ('card' == widget.compType) {
    if (widget.properties.components) {
      widget.properties.components = widget.properties.components.map((cardComponent: any, cardComponentIndex: number) => {
        return removeAuxiliaryPropsInChildren(cardComponent);
      });
    }
  } else if ('row' == widget.compType) {
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components = gridColumn.components.forEach((gridCellWidget: any, gridCellWidgetIndex: number) => {
          if (gridCellWidget.dataSource.pageInfo) {
            delete gridCellWidget.dataSource.pageInfo;
          }
          return gridCellWidget;
        });
      }
    });
  } else if ('spliter' == widget.compType) {
    widget.properties.gridColumns.forEach((gridColumn: any) => {
      if (gridColumn.components) {
        gridColumn.components = gridColumn.components.forEach((gridCellWidget: any) => {
          return removeAuxiliaryPropsInChildren(gridCellWidget);
        });
      }
    });
  } else if ('table' == widget.compType) {
    if (widget.properties.components) {
      widget.properties.components = widget.properties.components.forEach((tableCellWidget: any, tableCellWidgetIndex: number) => {
        if (tableCellWidget.dataSource.pageInfo) {
          delete tableCellWidget.dataSource.pageInfo;
        }
        return tableCellWidget;
      });
    }
  } else {
    if (widget.dataSource.pageInfo) {
      delete widget.dataSource.pageInfo;
    }
    return widget;
  }
}

// 构建表单控件权限配置树
export function buildWidgetAuthMap(widgets: any) {
  let widgetMap: any = {};
  if (widgets && widgets.length) {
    widgets.forEach((widgetItem: any) => {
      widgetMap[widgetItem.componentsCode] = widgetItem;
      if (widgetItem.children) {
        widgetMap = Object.assign(widgetMap, buildWidgetAuthMap(widgetItem.children));
      }
    });
  }
  return widgetMap;
}

/**
 * 获取表单中所有指定类型的组件
 */
export function getFormWidgetsByCompType(components: any[], compType: any) {
  let result: any[] = [];
  components.forEach(compItem => {
    if (compItem.compType == compType) {
      result.push(compItem);
    }
    if (compItem.compType == 'card') {
      result = result.concat(getFormWidgetsByCompType(compItem.properties.components, compType));
    } else if (compItem.compType == 'row') {
      compItem.properties.gridColumns.forEach((gridCol: any) => {
        result = result.concat(getFormWidgetsByCompType(gridCol.components, compType));
      });
    } else if (compItem.compType == 'table') {
      result = result.concat(getFormWidgetsByCompType(compItem.properties.components, compType));
    } else if (compItem.compType == 'spliter') {
      compItem.properties.gridColumns.forEach((gridCol: any) => {
        result = result.concat(getFormWidgetsByCompType(gridCol.components, compType));
      });
    }
  });
  return result;
}

export function getFormSettingFlat(formSetting: any) {
  let result: any[] = [];
  formSetting.forEach((item: any) => {
    let tmp = { ...item };
    if (tmp.children) {
      result = result.concat(getFormSettingFlat(tmp.children));
      delete tmp.children;
    }
    result.push(tmp);
  });
  return result;
}
