import HttpUtil from '@/utils/HttpUtil';

export const getWsUrl = () => {
  var url = '/river-system/assist/ws/address';
  return HttpUtil.get(url, {});
};

export const getImUrl = () => {
  var url = '/river-system/assist/im/address';
  return HttpUtil.get(url, {});
};
