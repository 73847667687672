export default {
  title: '一体化管理平台', // 项目名称
  homePage: {
    name: '首页',
    path: '/wel/index'
  },
  auth: false, // 是否校验登录
  sign: false, // 是否开启签名
  sso: true, // 是否开启单点登录
  storate: {
    expireTime: 1000 * 60 * 60 * 3,
    sessionStorageKey: 'SESSION',
    localStorageKey: 'LOCAL'
  },
  ras: {
    open: true,
    publicKey:
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgg0K7kYWmGD4nrsCEq0WhtHzo/gJkapHMrqKolASnNuTPEOI5j0h4tgZ89A2ljnl3Q6Qh9+MHqefl1Q1p0a3eYscTod47YieLMpp0404m04JzlHJSryO6kOU11FseDkxQGJc3LqBDmKdb/YuV4cAszXMuOo8WZjo54935B1ohgQIDAQAB'
  }
};
