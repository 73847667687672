// import { NodeConfig } from '@logicflow/core';
// import {FlowModel} from '@/models/flow/FlowModel';
import { FlowData } from '@/models/flow/FlowModel';

export default {
  state: {
    flowConfig: FlowData,
    activedNode: {},
    taskHistories: {},
    currentTask: {},
    processLogs: []
    // flowConfig: FlowModel,
    // activedNode: NodeConfig,
  },
  mutations: {
    SET_FLOW_CONFIG(state: any, data: any) {
      state.flowConfig = data;
    },
    SET_ACTIVED_NODE(state: any, data: any) {
      state.activedNode = data;
    },
    SET_TASK_HISTORIES(state: any, data: any) {
      state.taskHistories = data;
    },
    SET_CURRENT_TASK(state: any, data: any) {
      state.currentTask = data;
    },
    SET_PROCESS_LOGS(state: any, data: any) {
      state.processLogs = data;
    }
  },
  getters: {
    /**
     * @description: 获取当前表单配置
     * @return:
     */
    flowConfig: (state: any) => {
      return state.flowConfig;
    },

    // 获取当前选中的组件
    activedNode: (state: any) => {
      return state.activedNode;
    },

    taskHistories: (state: any) => {
      return state.taskHistories || [];
    },

    currentTask: (state: any) => {
      return state.currentTask || {};
    },
    // 获取当前选中的组件
    processLogs: (state: any) => {
      return state.processLogs;
    }
  }
};
