export interface PageModel {
  pageIndex: number;
  pageSize: number;
  total: number;
  list: any[];
}

export const PageData: PageModel = {
  pageIndex: 0,
  pageSize: 20,
  total: 0,
  list: []
};
export interface PageQoModel {
  pageIndex: number;
  pageSize: number;
}

export const PageQoData: PageQoModel = {
  pageIndex: 1,
  pageSize: 10
};
export interface PageInfoModel extends PageModel {
  endRow: number,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  isFirstPage: boolean,
  isLastPage: boolean,
  list: any[],
  navigateFirstPage: number,
  navigateLastPage: number,
  navigatePages: number,
  nvigatepageNums: number[],
  nextPage: number,
  pageNum: number,
  pageSize: number,
  pages: number,
  prePage:number,
  size:number,
  startRow: number,
  total: number
}
