import { WidgetModel } from './WidgetModel';

export interface WidgetPropertiesModel {
  label: string;
  labelWidth?: number;
  compIcon: string;
  showLabel: boolean;
  layout: string;
  placeholder: string;
  width?: number;
  clearable: boolean;
  disabled: boolean;
  readonly: boolean;
  hidden: boolean;
  required: boolean;
  operation: string;

  closable?: boolean;
  center?: boolean;
  closeText?: string;
  showIcon?: boolean;
  effect?: string;
  title?: string;
  description?: string;

  plain?: boolean;
  round?: boolean;
  circle?: boolean;
  loading?: boolean;
  icon?: string;

  height?: number;
  marginTop?: number;
  marginBottom?: number;
  components?: WidgetModel[];

  expandTrigger?: string;
  checkStrictly?: boolean;
  showAllLevels?: boolean;
  separator?: string;
  filterable?: boolean;
  serialType?: any;

  flat?: boolean;
  disableBranchNodes?: boolean;
  valueConsistsOf?: string;

  optionType?: string; //checkbox
  border?: boolean;
  vertical?: boolean;
  size?: string; //size
  showAlpha?: boolean;
  predefine?: string[];

  maxLength?: number; //date
  type?: string;
  format?: string;

  showIndex?: boolean;
  colConf?: string;
  dval?: string;
  dlabel?: string;
  summary?: boolean;
  summaryColumnKeys?: any[];

  contentPosition?: string; //divider
  fontColor?: string;
  lineColor?: string;
  lineWidth?: number;
  fontSize?: string;

  validateMaxText?: boolean; //editor

  gutter?: number; // row
  gridColumns?: GridItemPropertiesModel[];

  show?: boolean;
  stepStrictly?: boolean;
  precision?: number;
  controlsPosition?: string;

  prefixIcon?: string;
  suffixIcon?: string;

  href?: string;
  underline?: boolean;
  target?: string;
  text?: string;

  selectableRange?: string; // picker

  allowHalf?: boolean;
  showScore?: boolean;

  min?: number; // slider
  max?: number;
  step?: number;
  range?: boolean;
  showStops?: boolean;
  showTooltip?: boolean;

  activeColor?: string; //switch
  inactiveColor?: string;
  activeValue?: string;
  inactiveValue?: string;

  maxlength?: number; // textarea
  showWordLimit?: boolean;
  rows?: number;

  multiple?: boolean; // upload
  buttonText?: string;
  action?: string;
  accepts?: string[];
  limit?: number;
  maxSize?: number;
  downloadable?: boolean;
  previewable?: boolean;
  showFileList?: boolean;
  listType?: string;

  serialNumberSetting?: SerialNumberSetting;
}

export interface GridItemPropertiesModel {
  index: number;
  span: number;
  components?: WidgetModel[];
}

export interface SerialNumberSetting {
  length: number;
  serialParts: SerialPart[];
}

export interface SerialPart {
  type: SerialPartType | string;
  varName?: string;
  formModelCode?: string;
}

export const WidgetPropertiesData: WidgetPropertiesModel = {
  label: '',
  labelWidth: 120,
  compIcon: '',
  showLabel: true,
  layout: '',
  placeholder: '',
  clearable: true,
  disabled: false,
  readonly: false,
  hidden: false,
  required: false,
  contentPosition: 'left',
  operation: 'default'
};

export enum SerialPartType {
  FORM_MODEL = '组件',
  VARIABLE = '参数',
  DATE = '日期'
}
