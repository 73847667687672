import {InjectionKey} from "vue";
import {createStore, Store} from "vuex";
import CommonStore from "@/store/modules/CommonStore";
import FormStore from "@/store/modules/FormStore";
import FlowStore from "@/store/modules/FlowStore";

export const key: InjectionKey<Store<any>> = Symbol();

export const store = createStore<any>({
    modules: {
        common: CommonStore,
        form: FormStore,
        flow: FlowStore
    },
});
