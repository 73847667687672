import { PageData, PageModel } from '../common/CommonModel';

export const WidgetDataSourceTypeEnum: Record<string, string> = {
  COMPLEX: 'COMPLEX',
  SIMPLE: 'SIMPLE'
};

export const WidgetDataSourceConfigTypeEnum: Record<string, string> = {
  GROUP_CONFIG: 'GROUP_CONFIG',
  USER_CONFIG: 'USER_CONFIG',
  ROLE_CONFIG: 'ROLE_CONFIG',
  APP_CONFIG: 'APP_CONFIG',
  FLOW_CONFIG: 'FLOW_CONFIG',
  OPINION_CONFIG: 'OPINION_CONFIG',
  OPTION_CONFIG: 'OPTION_CONFIG',
  FORM_CONFIG: 'FORM_CONFIG'
};

export interface WidgetDataSourceConditionModel {
  key: string;
  source: string;
  value: unknown;
  result: unknown;
  filter: any;
  compare: string;
  logical: string;
  first: boolean;
  dataSource: any[];
  operatorList: any[];
  tableName: string;
}

export const WidgetDataSourceConditionData: WidgetDataSourceConditionModel = {
  key: '',
  source: 'SELECT',
  value: null,
  result: null,
  filter: { type: 'STRING' },
  compare: '',
  logical: 'AND',
  first: true,
  operatorList: [],
  dataSource: [],
  tableName: ''
};
export interface WidgetDataSourceConditionGroupModel {
  key: string;
  logical: string;
  conditions: WidgetDataSourceConditionModel[];
  first: boolean;
}

export const WidgetDataSourceConditionGroupData = [
  {
    key: 'group1',
    logical: 'AND',
    conditions: [WidgetDataSourceConditionData],
    first: true
  }
];

export interface WidgetDataSourceConditionConfigModel {
  conditions: any[];
  dataListConditions: any[];
}

export const WidgetDataSourceConditionConfigData: WidgetDataSourceConditionConfigModel = {
  conditions: [],
  dataListConditions: []
};

export interface WidgetDataSourceColumnModel {
  title: string;
  key: string | null;
  value: any;
  index: number;
  display: boolean;
  visible: boolean;
  val: boolean;
  html: boolean;
  search: boolean;
}

export const WidgetDataSourceColumnData: WidgetDataSourceColumnModel = {
  title: '',
  key: null,
  value: {},
  index: 0,
  display: false,
  visible: false,
  val: false,
  html: false,
  search: false
};

export interface WidgetDataSourceColumnConfigModel {
  defaultSimpleColumn: WidgetDataSourceColumnModel;
  defaultComplexColumns: WidgetDataSourceColumnModel[];
  customSimpleColumn: WidgetDataSourceColumnModel;
  customComplexColumns: WidgetDataSourceColumnModel[];
}

export const WidgetDataSourceColumnConfigData: WidgetDataSourceColumnConfigModel = {
  defaultSimpleColumn: WidgetDataSourceColumnData,
  defaultComplexColumns: [],
  customSimpleColumn: WidgetDataSourceColumnData,
  customComplexColumns: []
};

export interface WidgetDataSourceConfigModel {
  // 公共属性
  name: string;
  type: string;
  columnConfig: WidgetDataSourceColumnConfigModel;
  conditionConfig: WidgetDataSourceConditionConfigModel;

  // 静态配置-属性
  normalDataList: any[];

  // 表单数据-属性
  formCode: string | null;
  configurable?: boolean;

  // 意见数据源配置
  processCode: string | null;
  lastNode: boolean;
  lastOperator: boolean;
}

// conditions: any[];
// 动态配置-属性
// dataListConditions: WidgetDataSourceConditionGroupModel[];
export const WidgetDataSourceConfigData: WidgetDataSourceConfigModel = {
  type: '',
  name: '',
  conditionConfig: WidgetDataSourceConditionConfigData,
  columnConfig: WidgetDataSourceColumnConfigData,

  // 静态配置-属性
  normalDataList: [],

  // 表单数据-属性
  formCode: null,
  processCode: null,
  lastNode: false,
  lastOperator: false
};

export const WidgetDataSourceConfigGroups = {
  NORMAL: '普通类',
  DYNAMIC: '动态类',
  INTERFACE: '接口类'
};

export interface FactorModel {
  id: string;
  type: string;
  value: any;
  label: string;
  children: FactorModel[];
}

export const FactorData: FactorModel = {
  id: '',
  type: '',
  value: '',
  label: '',
  children: []
};
export interface WidgetDefaultDataConfigModel {
  type: string;
  formula: FactorModel[];
  simpleDefaultDataList: any[];
  complexDefaultDataList: any[];
  order: number;
  valueType: string;
}

export const WidgetDefaultDataConfigData = {
  type: '',
  formula: [],
  simpleDefaultDataList: [],
  complexDefaultDataList: [],
  order: 0,
  valueType: 'FIXED_VALUE'
};

export interface WidgetDataSourceModel {
  type: string;
  dataList: any[];
  defaultValue: any[];
  dataSourceConfig: WidgetDataSourceConfigModel;
  defaultDataConfig: WidgetDefaultDataConfigModel;
  pageInfo: PageModel;
  searchParams: any;
}

export const WidgetDataSourceData: WidgetDataSourceModel = {
  type: 'SIMPLE',
  dataList: [],
  defaultValue: [],
  dataSourceConfig: WidgetDataSourceConfigData,
  pageInfo: PageData,
  defaultDataConfig: WidgetDefaultDataConfigData,
  searchParams: {}
};
